import React, { useState } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Sidebar from 'components/Sidebar';
import Dashboard from 'pages/Dashboard';
import Settings from 'pages/Settings';
import Tables from 'pages/Tables';
import Footer from 'components/Footer';
import Login from 'pages/Login';

// Tailwind CSS Style Sheet
import 'assets/styles/tailwind.css';

function App() {
    const [isLoggedIn, setIsLoggedIn] = useState(false); // Нэвтрэх төлөв

    return (
        <>
            {!isLoggedIn ? (
                <Switch>
                    <Route exact path="/">
                        <Login onLogin={() => setIsLoggedIn(true)} />
                    </Route>
                    <Redirect to="/" />
                </Switch>
            ) : (
                <>
                    <Sidebar />
                    <div className="md:ml-64">
                        <Switch>
                            <Route exact path="/dashboard" component={Dashboard} />
                            <Route exact path="/settings" component={Settings} />
                            <Route exact path="/tables" component={Tables} />
                            <Redirect to="/dashboard" />
                        </Switch>
                        <Footer />
                    </div>
                </>
            )}
        </>
    );
}

export default App;
