import React, { useState } from 'react';
import BackgroundImage from 'assets/img/443713076_444553731495939_1831251878626409599_n.jpg'; // Дэвсгэр зураг оруулна

export default function Login({ onLogin }) {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

    const handleLogin = async (e) => {
        e.preventDefault(); // Формаа дахин ачаалахаас сэргийлнэ
        // onLogin();
        try {
            // Сервер рүү хүсэлт илгээх
            const response = await fetch('https://amafitness.fit:5000/auth/login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ username, password }),
            });

            // Серверээс ирсэн хариуг шалгах
            const data = await response.json();

            if (response.ok) {
                // Амжилттай нэвтрэлт
                const token = data.token;
                localStorage.setItem('token', token);
                console.log('Амжилттай нэвтэрлээ:', data);
                onLogin(data.token); // JWT токенийг эцэг компонент руу дамжуулах
            } else {
                setError(data.message || 'Нэвтрэх үед алдаа гарлаа.');
            }
        } catch (err) {
            console.error('Сервертэй холбогдох үед алдаа гарлаа:', err);
            setError('Сервертэй холбогдох үед алдаа гарлаа.');
        }
    };

    return (
        <div
            className="flex items-center justify-center min-h-screen bg-gray-100"
            style={{
                backgroundImage: `url(${BackgroundImage})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
            }}
        >
            <div className="bg-white shadow-md rounded-lg p-8 w-96 bg-opacity-90 backdrop-blur-md">
                <h2 className="text-2xl font-bold text-center text-blue-600 mb-6">AMA Fitness Center</h2>
                {error && (
                    <div className="text-red-500 text-sm mb-4 text-center">
                        {error}
                    </div>
                )}
                <form onSubmit={handleLogin}>
                    <div className="mb-4">
                        <label
                            htmlFor="email"
                            className="block text-gray-700 text-sm font-bold mb-2"
                        >
                            И-мэйл хаяг эсвэл нэр
                        </label>
                        <input
                            type="text"
                            id="email"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            placeholder="name@example.com"
                            className="w-full px-4 py-2 border rounded-lg text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                    </div>
                    <div className="mb-4">
                        <label
                            htmlFor="password"
                            className="block text-gray-700 text-sm font-bold mb-2"
                        >
                            Нууц үг
                        </label>
                        <input
                            type="password"
                            id="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            placeholder="********"
                            className="w-full px-4 py-2 border rounded-lg text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                    </div>
                    <div className="mb-4 text-center">
                        <button
                            type="submit"
                            className="w-full bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        >
                            Нэвтрэх
                        </button>
                    </div>
                    <div className="text-center">
                        <a
                            href="#"
                            className="text-blue-500 text-sm hover:underline"
                        >
                            Нууц үгээ мартсан уу?
                        </a>
                    </div>
                </form>
            </div>
        </div>
    );
}
