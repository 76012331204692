import { useLocation } from 'react-router-dom'; // useHistory-г устгана
import Button from '@material-tailwind/react/Button';
import Icon from '@material-tailwind/react/Icon';
import Dropdown from '@material-tailwind/react/Dropdown';
import DropdownItem from '@material-tailwind/react/DropdownItem';

export default function AdminNavbar({ showSidebar, setShowSidebar }) {
    const location = useLocation().pathname; // Одоогийн замыг авах

    // Path-д тохирох гарчиг тодорхойлох функц
    const getPageTitle = () => {
        switch (location) {
            case '/dashboard':
                return 'Хяналтын самбар';
            case '/settings':
                return 'Төлбөр';
            case '/tables':
                return 'Бүртгэл';
            default:
                return location === '/' ? 'Хяналтын самбар' : location.toUpperCase().replace('/', '');
        }
    };

    // Гарах товч дээр дарсан event
    const handleLogout = async () => {
        // try {
        //     // Сервертэй холбоо хийх хүсэлт
        //     const response = await fetch('/api/logout', {
        //         method: 'POST',
        //         credentials: 'include', // Cookie зэрэг мэдээллийг дамжуулах
        //     });

        //     if (response.ok) {
        //         console.log('Амжилттай гарлаа');
                window.location.href = '/'; // Login хуудас руу шилжих
        //     } else {
        //         console.error('Гарах үйлдэл амжилтгүй боллоо');
        //     }
        // } catch (error) {
        //     console.error('Сүлжээний алдаа:', error);
        // }
    };

    return (
        <nav className="bg-light-blue-500 md:ml-64 py-6 px-3">
            <div className="container max-w-full mx-auto flex items-center md:pr-8 md:pl-10">
                <div className="md:hidden">
                    <Button
                        color="transparent"
                        buttonType="link"
                        size="lg"
                        iconOnly
                        rounded
                        ripple="light"
                        onClick={() => setShowSidebar('left-0')}
                    >
                        <Icon name="menu" size="2xl" color="white" />
                    </Button>
                    <div
                        className={`absolute top-2 md:hidden ${
                            showSidebar === 'left-0' ? 'left-64' : '-left-64'
                        } z-50 transition-all duration-300`}
                    >
                        <Button
                            color="transparent"
                            buttonType="link"
                            size="lg"
                            iconOnly
                            rounded
                            ripple="light"
                            onClick={() => setShowSidebar('-left-64')}
                        >
                            <Icon name="close" size="2xl" color="white" />
                        </Button>
                    </div>
                </div>

                <div className="flex justify-between items-center w-full">
                    <h4 className="uppercase text-white text-sm tracking-wider mt-1">
                        {getPageTitle()}
                    </h4>

                    <div className="flex">
                        <div className="-mr-4 ml-6">
                            <Dropdown
                                color="transparent"
                                buttonText={
                                    <div className="w-12">
                                        <Icon name="person" size="2xl" color="white" />
                                    </div>
                                }
                                rounded
                                style={{
                                    padding: 0,
                                    color: 'transparent',
                                }}
                            >
                                <DropdownItem onClick={handleLogout}>
                                    <div className="flex items-center gap-2">
                                        <Icon name="logout" size="md" color="black" />
                                        Гарах
                                    </div>
                                </DropdownItem>
                            </Dropdown>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    );
}
